import {createRouter, createWebHistory} from 'vue-router'
import DefaultView from "@/views/DefaultView";
import LinksView from "@/views/LinksView";

const routes = [
    //Landing
    {path: '/', name: 'landing', component: DefaultView},
    {path: '/links', name: 'links', component: LinksView},


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     /*Scroll To Saved Position*/
    //     if (savedPosition) {
    //         return new Promise((resolve) => {
    //             setTimeout(() => {
    //                 resolve({
    //                     top: savedPosition.top,
    //                     behavior: 'smooth',
    //                 })
    //             }, 400)
    //         })
    //     }
    //     /*Scroll To Hash*/
    //     if (to.hash) {
    //         return {
    //             el: to.hash,
    //             // selector: to.hash,
    //             behavior: 'smooth',
    //             // top: 180,
    //             top: 250
    //         }
    //     }
    //     /*Scroll To Top (Default)*/
    //     return {
    //         top: 0,
    //         behavior: 'smooth',
    //     }
    // },
})
export default router