<template>
<SectionProgram/>
  <SectionPlayer/>

<!--  <SectionPromo/>-->
  <SectionIntro/>
  <SectionExpandInfo/>


</template>

<script>
import SectionProgram from "@/components/SectionProgram";
import SectionPlayer from "@/components/SectionPlayer";
import SectionIntro from "@/components/SectionIntro";
import SectionExpandInfo from "@/components/SectionExpandInfo";
export default {
  name: "DefaultView",
  components: {SectionExpandInfo, SectionIntro, SectionPlayer, SectionProgram}
}
</script>

<style scoped>

</style>