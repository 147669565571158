<template>
  <main id="content">
    <router-view :key="$route.path"></router-view>
  </main>
  <PartFooter/>
  <PartPlayerAudio/>
</template>

<script>
import PartPlayerAudio from "@/components/PartPlayerAudio";
import PartFooter from "@/components/PartFooter";

export default {
  name: 'App',
  components: {
    PartFooter,
    PartPlayerAudio,
  }
}
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";

@import "./assets/keyframes.css";
@import "./assets/global.css";
@import "./assets/fw.min.css";



</style>
