import { createApp } from 'vue'
import App from './Radiopolis.vue'
const RadioPolis = createApp(App);



//Vue Router
import router from '@/router'
RadioPolis.use(router);


RadioPolis.mount('#app')