<template>
  <section id="intro" class="">
    <div class="section-wrapper">

        <div class="expand" :class="isSectionExpanded">
          <div class="container-lg container-fluid container-max">
          <div class="expand-header" @click.prevent="toggleExpand">Info</div>
          <div class="expand-body" ref="expand-body">
            <div class="expand-body-inner" ref="expand-body-inner">
              <div class="text">
                <p>Wir treten für ein solidarisches und nicht-profitorientiertes Meinungsbild ein. Sämtliche Formen von
                Diskriminierung, Rassismus, Sexismus und Homophobie lehnen wir entschlossen ab.</p>

                <p>Wir freuen uns über sämtliche Inputs und Mitmachbereitschaft.
                Hast du eine Idee oder Beiträge, welche deiner Meinung nach in unser Programm passen würden?
                Melde dich bei uns und wir melden uns zurück.</p>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionExpandInfo",
  data() {
    return {
      sectionExpanded: false,
    }
  },
  computed: {
    isSectionExpanded() {
      return this.sectionExpanded ? 'expanded':'collapsed';
    }
  },
    methods: {
    toggleExpand() {
      this.sectionExpanded = !this.sectionExpanded;
    }
  }
}
</script>

<style scoped>
.expand.expanded, .expand:hover {
  background-color: var(--color-blue);
}

.expand {
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: background-color ease var(--transition-time-1);

}

.expand .expand-header {
  font-size: 10rem;
  text-transform: uppercase;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 10rem;
  color: var(--color-white);
  cursor: pointer;
}

.expand .expand-body {
  overflow: hidden;
  max-height: 0px;
  transition: all ease var(--transition-time-1);
}
.expand.expanded .expand-body {
max-height: 800px;
}

@media (max-width: 992px) {
  .expand .expand-header {
    font-size: 5rem;
    line-height: 4.5rem;

  }
}
</style>