<template>
  <footer>
    <div class="container-fluid">
      <div class="links">
        <div><a href="https://t.me/radiopolis" target="_blank">chat: t.me/radiopolis</a></div>
        <div><a href="https://www.instagram.com/radiopolis.xyz/" target="_blank">instagram: @radiopolis.xyz</a></div>
        <div><a href="mailto:studio@radiopolis.xyz">mail: studio@radiopolis.xyz</a></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PartFooter"
}
</script>

<style scoped>
footer {
  background-color: var(--color-red);

}

footer .links {
  height: var(--footer-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .links a {
  font-style: normal;
  font-variant: normal;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1em;
  color: var(--color-white);
}

footer .links a:hover {
  font-style: italic;
}

@media (max-width: 992px) {
  footer .links {
    justify-content: space-around;
  flex-direction: column;
  }

}


</style>