<template>
  <SectionProgram section-class="small"/>
  <SectionPlayer section-class="small"/>
  <section id="links">
    <div class="section-wrapper">
      <div class="container-lg container-fluid container-max-narrow">
        <div class="row gutb-2">
          <div class="col-12" v-for="link in this.links" :key="link">
            <a v-bind:href="link.url" class="link" v-bind:target="link.target">
              <div class="heading" v-html="link.heading"/>
              <div class="sub" v-html="link.sub"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


</template>

<script>
import SectionProgram from "@/components/SectionProgram";
import SectionPlayer from "@/components/SectionPlayer";

export default {
  name: "LinksView",
  components: {SectionPlayer, SectionProgram},
  data() {
    return {
      links: {
        party: {
          url: 'https://www.humbug.club/events/radiopolis-presents-stufe-3-massive-eskalation',
          heading: 'Stufe 3: massive eskalation',
          sub: '21.4.23 @Humbug',
          target: '_blank'
        },
        petzi: {
          url: 'https://www.petzi.ch/de/events/52347-humbug-radiopolis-presents-stufe-3-massive-eskalation/',
          heading: 'Stufe 3: Tickets',
          sub: 'petzi.ch',
          target: '_blank'
        },
        transitionless: {
          url: 'https://soundcloud.com/transitionless',
          heading: 'Transitionless',
          sub: 'soundcloud.com',
          target: '_blank'
        },
        suvatne: {
          url: 'https://soundcloud.com/kristiansuvatne',
          heading: 'suvatne',
          sub: 'soundcloud.com',
          target: '_blank'
        },
        cybebe: {
          url: 'https://soundcloud.com/user-695939923',
          heading: 'cybebe',
          sub: 'soundcloud.com',
          target: '_blank'
        },
        benigeier: {
          url: 'https://soundcloud.com/benigeier',
          heading: 'Beni Geier',
          sub: 'soundcloud.com',
          target: '_blank'
        },
        qrt: {
          url: 'https://soundcloud.com/aaronqrt',
          heading: 'q-rt',
          sub: 'soundcloud.com',
          target: '_blank'
        },
        radiopolis: {
          url: 'https://soundcloud.com/radiopolisxyz',
          heading: 'radiopolis',
          sub: 'soundcloud.com',
          target: '_blank'
        },
      }

    }
  },
}
</script>

<style scoped>
a.link {
  cursor: pointer;
  width: 100%;
  border: solid 2px var(--color-blue);
  color: var(--color-white);
  padding: 0.7rem 0;
  color: var(--color-white);
  text-align: center;
  display: block;
  transition: background-color ease var(--transition-time-1), color ease var(--transition-time-1);
  text-decoration: none;


}

a.link:hover {
  background-color: var(--color-blue);
}

a.link div {
}

a.link .heading {
  font-size: 2rem;
  text-transform: uppercase;

}
</style>