import axios from "axios";

// let apiUrlBase = 'https://api.radiopolis.xyz/';
// let apiUrlBase = 'https://stream.radiopolis.ch/programm/';

export function getCurrentProgram() {
    return axios
        .get('https://stream.radiopolis.xyz/program/')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}
