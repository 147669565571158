<template>
  <audio id="playeraudio" type="audio/mp3" src="" preload="none" ></audio>
</template>

<script>
export default {
  name: "PartPlayerAudio",
}
</script>

<style scoped>

</style>