<template>
<section id="intro" class="">
  <div class="section-wrapper">
  <div class="container-lg container-fluid container-max">

  <div class="text txt-aln-justify">
    <p>Radiopolis ist ein Quartierradio mit möglichst grenzenloser Reichweite.</p>

    <p>Wir wollen möglichst vielen Menschen eine Plattform bieten und so die kulturelle und thematische Vernetzung fördern.</p>

    <p>Dem Programm sind nur die Grenzen des Radioformates gesetzt. Von Live Sets, Gesprächen und weiteren Ideen ist alles möglich.</p>

  </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: "SectionIntro"
}
</script>

<style scoped>

section {
  /*background-color: blue;*/
}
</style>