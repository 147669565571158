<template>
  <section id="player" class="" :class="[isPlayerPlaying, sectionClass]">
  <div id="transport" @click.prevent="togglePlayer">
    <div class="controls-icon play"></div>
    <div class="controls-icon pause"></div>

  </div>
  </section>
</template>

<script>
export default {
  name: "SectionPlayer",
  props: {
    sectionClass: String
  },
  data() {
    return {
      playerPlaying: false,
      playerStreamUrlOLD: 'https://rs01.swissnwx.ch:30080/stream',
      // playerStreamUrl: 'https://stream.radiopolis.xyz',
      playerStreamUrl: 'https://rs01.swissnwx.ch:30080/stream',
      playerProgram: ' ',
    }
  },
  computed: {
    isPlayerPlaying(){
      return this.playerPlaying ? 'playing' : 'paused';
    }
  },
  methods: {
    togglePlayer() {
      var playerAudio = document.getElementById('playeraudio');
      if(!this.playerPlaying) {
        playerAudio.setAttribute('src', this.playerStreamUrl);
        playerAudio.play();
        this.playerPlaying = true;
      }
      else if(this.playerPlaying) {
        playerAudio.setAttribute('src', '');
        this.playerPlaying = false;

      }
    }
  },
}
</script>

<style scoped>
#player {
}
#transport {
  display: block;
  position: relative;
  width: 100%;
  height: var(--player-height);
  margin: auto;
  background-color: var(--color-black);
  cursor: pointer;
  overflow: hidden;
  transition: all ease var(--transition-time-1);
}
#transport:hover, #player.playing #transport {
  background-color: var(--color-blue);
}
#transport > .controls-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: calc(var(--player-transport-size) * 2);
  border-color: transparent transparent transparent #fff;
  transition: all var(--transition-time-1) ease;
  cursor: pointer;
}
#transport .controls-icon.play {
  transform: translate(-50%,-50%);
  border-style: solid;
  border-width: var(--player-transport-size) 0 var(--player-transport-size) calc(var(--player-transport-size) * 1.66666);
}
#transport .controls-icon.pause {
  border-style: double;
  border-width: 0 0 0 calc(var(--player-transport-size) * 1.66666);
}
#player.paused .controls-icon.pause {
  transform: translate(-50%,calc(var(--player-transport-size) * 8));
}
#player.playing .controls-icon.play {
  transform: translate(-50%,calc(var(--player-transport-size) * -4));
}

#player.small #transport {
  height: var(--player-height-small);
}
#player.small #transport > .controls-icon {
  height: calc(var(--player-transport-size-small) * 2);
}
#player.small #transport .controls-icon.play {
  border-width: var(--player-transport-size-small) 0 var(--player-transport-size-small) calc(var(--player-transport-size-small) * 1.66666);
}
#player.small #transport .controls-icon.pause {
  border-width: 0 0 0 calc(var(--player-transport-size-small) * 1.66666);
}
#player.small #player.paused .controls-icon.pause {
  transform: translate(-50%,calc(var(--player-transport-size-small) * 8));
}
#player.small #player.playing .controls-icon.play {
  transform: translate(-50%,calc(var(--player-transport-size-small) * -4));
}
</style>
